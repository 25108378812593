<template>
  <div>
    <v-container>
      <h1 class="pb-6">Set Up Sensors</h1>

      <v-card rounded="lg" class="pa-6">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <h4>Start Date</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <h4>End Date</h4>
              <v-text-field outlined></v-text-field>
            </v-col>

            <v-col class="d-flex justify-center" cols="12">
              <v-btn depressed color="primary">Submit</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card class="my-6" outlined>
                <v-container>
                  <h3 class="pt-4 pb-6">Sensor #1</h3>
                  <v-row>
                    <v-col cols="6">
                      <h4>Sensor ID</h4>
                      <v-text-field outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <h4>Serial Number</h4>
                      <v-text-field outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <h4>Model</h4>
                      <v-text-field outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <h4>Sensor Location</h4>
                      <v-text-field outlined></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <h4>Sensor Type</h4>
                      <v-select outlined></v-select>
                    </v-col>
                    <v-col cols="6">
                      <h4>Installation Date</h4>
                      <v-text-field outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <h4>Expiration Date</h4>
                      <v-text-field outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <h4>Upload An Image</h4>
                      <v-file-input
                        outlined
                        prepend-icon="mdi-camera"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="6">
                      <h4>Add Geolocation</h4>
                      <v-text-field
                        outlined
                        prepend-icon="mdi-map-marker"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-btn depressed color="primary">
                <v-icon left>mdi-plus</v-icon>
                Add More Sensors
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sensors: [{}],
    };
  },
  methods: {
    addSensor() {},
  },
};
</script>

<style></style>
